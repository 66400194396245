import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueKinesis from 'vue-kinesis'
import { CursorFx } from '@luxdamore/vue-cursor-fx'
import '@luxdamore/vue-cursor-fx/dist/CursorFx.css'
import SmoothVuebar from 'vue-smooth-scrollbar'
import VueScrollTo from 'vue-scrollto'
 
Vue.use(VueScrollTo)
 
// You can also pass in the default options
Vue.use(VueScrollTo, {
     container: "body",
     duration: 2500,
     easing: "ease-out",
     offset: 0,
     force: true,
     cancelable: true,
     onStart: false,
     onDone: false,
     onCancel: false,
     x: false,
     y: true
 })

Vue.use(SmoothVuebar, {
  listener: () => {},
  options: {}
})
Vue.config.productionTip = false

Vue.component(
  CursorFx.name,
  CursorFx
)
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

Vue.use(VueKinesis)
