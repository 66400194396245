<template>
  <div class="home">
  <kinesis-container>
      <header class="videoheader">
      <img class="r-top" src="@/assets/landing_element2.svg" alt="">
      <video autoplay muted loop id="myVideo">
        <source src="@/assets/motionreelfinal.mp4" type="video/mp4">
      </video>
      <div class="wrapping-content">
          <kinesis-element :strength="22" type="depth" >
          <article>
          <h1>Hi I'm Fady Elabed, a graphic designer</h1>
          </article>
          </kinesis-element>
      </div>
    <img class="l-bottom" src="@/assets/landing_element1.svg" alt="">
    <!-- <img data-cursor-hover class="arrow-down" v-scroll-to="'#nav'" src="@/assets/arrowdown.svg" alt=""> -->
    <p data-cursor-hover><router-link to="/home">Continue</router-link></p>
    </header>
  </kinesis-container>
        <!-- <div id="nav">
      <router-link to="/">Home</router-link>
      <router-link to="/about">About</router-link>
    </div> -->
            <cursor-fx>
    </cursor-fx>
  </div>
</template>

<script>
import { gsap } from 'gsap'



export default {
  mounted: function () {
    this.startAnimations()
  },
  methods: {
    startAnimations: function () {

      gsap.from('.l-bottom', {
        duration: 1.8,
        opacity: 0,
        delay: 2,
        x: -105,
        ease: 'power3.out'
      })
      gsap.from('.r-top', {
        duration: 2,
        opacity: 0,
        delay: 2.4,
        x: 105,
        ease: 'power3.out'
      })
      gsap.from('.videoheader h1', {
        duration: 2.4,
        opacity: 0,
        delay: 3,
        scale: 1.4,
        ease: 'power3.out'
      })
      gsap.to('.videoheader h1', {
        duration: 1.4,
        opacity: 0,
        delay: 5.2,
        scale: 0.86,
        ease: 'Power4.easeOut'
      })
      gsap.to('.videoheader video', {
        duration: 1.6,
        opacity: 1,
        delay: 6.3,
        ease: 'power3.out'
      })
      gsap.from('.arrow-down', {
        duration: 1.8,
        y: -35,
        opacity: 0,
        delay: 7.3,
        ease: 'power3.out'
      })

    }
  }
}
</script>
